@import '@elastic/eui/src/theme_amsterdam_light.scss';


.ql-toolbar.ql-snow {
	border: none;
}

.ql-editor:focus:focus-visible {
	outline-style: none;
}

.ql-container.ql-snow {
	border: none;
}

.LaneDroppable::-webkit-scrollbar {
	display: none;
}

.LaneDroppable {
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
}